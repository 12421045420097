import React from 'react'
import { Container } from 'react-bootstrap'
import cn from 'classnames'
import { about, rtl, first, about__image } from './style.module.scss'

export const AboutBlock = ({ title, description, image, isRtl = false, isFirst }) => (
  <Container as="section" className={cn(about, { [first]: isFirst })}>
    <div className={cn(about__image, { [rtl]: isRtl })}>
      <img src={image} alt="Block icon" />
    </div>
    <div>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  </Container>
)
