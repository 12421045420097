import * as React from 'react'

import Layout from '~components/Layout'
import SEO from '~components/seo'
import Hero from '~components/Hero'

import { Table } from '~components/Table'

import { AboutBlock } from '~components/AboutBlock'

import DATA from './data'

export const Technology = () => (
  <Layout>
    <SEO title="Technology" />
    <Hero {...DATA.HERO} modifier="technology" />
    {DATA.ABOUT.map((block, index) => (
      <AboutBlock {...block} key={block.title} isRtl={index % 2} isFirst={index === 0} isImgCentered />
    ))}
    <Table data={DATA.TABLE} selectedColumns={[2]} modifier="technology" />
  </Layout>
)
