import tokenBlockchain from '~img/technology_blockchain.svg'
import tokenPoweredPlatform from '~img/technology_powered_platform.svg'

export default {
  HERO: {
    title: 'Techno&shy;logy',
    description: 'SCT token is based on the Solana-based token standard.',
  },
  ABOUT: [
    {
      title: 'Blockchain',
      description:
        'SolClout is completely powered by blockchain technology. From account generation to messaging, all activities on the platform are recorded on-chain. This technological structuring provides users the users the security and anonymity.',
      image: tokenBlockchain,
    },
    {
      title: 'Solana powered Platform',
      description:
        'As a blockchain-based platform, scalability and security is very important. SolClout is built on Solana, which is a growing public blockchain platform receiving spotlight for its safety and speed. The speed and security Solana provides will help SolClout reach mass adoption.',
      image: tokenPoweredPlatform,
    },
  ],
  TABLE: [
    ['List of features', 'Solana', 'Ethereum', 'EOS', 'Cardano', 'Tezos', 'Stellar'],
    ['Transaction Throughput', '59,000 tps', '17 tps', '3900 tps', '~250 tps', '50 tps', '~2000 tps'],
    ['Transaction Fee', '$0.00001', '~$2', 'Free', '~$0.02', '$0.00232', '$0.000001'],
    [
      'Transaction Finality',
      ['0.4 sec', '(1 block)'],
      ['5 min', '(35 blocks)'],
      ['2.5 mins', '2/3 of BPs'],
      '~2 mins',
      '30 mins',
      '4 sec',
    ],
    [
      'Consensus Mechanism',
      'Proof of Stake',
      'Proof of Work',
      ['Delegated Proof of', 'Stake'],
      ['Ouroboros Proof', 'of Stake'],
      ['Liquid Proof of', 'Stake'],
      ['Federated', 'Byzantine', 'Agreement'],
    ],
  ],
}
